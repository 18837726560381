import React from 'react';
import { Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import { colors } from 'src/constants/variables';
import { parseToPrice } from 'src/helpers/utils';
import { transformCatalogImageUrl } from 'src/helpers/utils';
import { calculatePricePerItem } from 'src/modules/offer/helpers/offerPrices';
import { PdfNorms } from '../pdfNorms/pdfNorms';
import { VariantItem } from '../../../../../helpers/types';
import ExpiredArticleImagePlaceholderPNG from '../../../../../assets/icons/expired_article_image_placeholder.png';
import ImagePlaceHolderPNG from '../../../../../assets/icons/no_image_placeholder.png';

const styles = StyleSheet.create({
    productListTableItem: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        minHeight: '52px',
        borderBottom: `0.5px solid ${colors.secondaryDark}`,
    },
    productImageWrapper: {
        position: 'relative',
        padding: '12px 0 0 5px',
        width: '30px',
    },
    productImage: {
        width: '30px',
    },
    productDetails: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        fontSize: '6px',
        padding: '9px 10px 5px 20px',
        width: '150px',
    },
    productDetailsModel: {
        fontFamily: 'GebauerBold',
        fontSize: '8px',
        maxWidth: '80%',
        marginBottom: '1px',
        color: colors.fontSecondaryDark,
    },
    productDetailsMaterial: {
        fontFamily: 'GebauerRegular',
        fontSize: '6px',
        color: colors.fontSecondaryDark,
        maxWidth: '80%',
        marginBottom: '1px',
    },
    productExpired: {
        textAlign: "left",
        marginTop: "11px",
        fontFamily: 'GebauerBold',
        fontSize: '8px',
        maxWidth: '80%',
        marginBottom: '1px',
        color: colors.fontSecondaryDark,
    },
    productListTableColumnBg: {
        backgroundColor: `rgba(217, 217, 217, 0.15)`,
    },
    productListTableCol34: {
        flexBasis: '34%',
        maxWidth: '34%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
    },
    productListTableCol9: {
        flexBasis: 'calc(9.42% - 4px)',
        maxWidth: 'calc(9.42% - 4px)',
        padding: '19px 2px 2px',
        fontSize: '8px',
        textAlign: 'center',
    },
    mediumText: {
        fontFamily: 'GebauerMedium',
    },
    textCapitalize: {
        textTransform: 'capitalize',
    },
    super: {
        fontFamily: 'GebauerRegular',
        color: colors.fontSecondary,
        textTransform: 'uppercase',
        fontSize: '6px',
    },
});

type Props = {
    item: VariantItem;
    displayCategory: boolean;
};

const COMMA = ',';

export const ProductListItem: React.FC<Props> = ({ item, displayCategory }) => {
    const productImage = item.imageLink ? (
        <Image
            style={styles.productImage}
            src={transformCatalogImageUrl(item?.imageLink, 250, true)}
        />
    ) : (
        <Image style={{ ...styles.productImage, width: '30px' }} src={item.expired ? ExpiredArticleImagePlaceholderPNG : ImagePlaceHolderPNG} />
    );

    const itemDisplayCategory = item.category === 'MBS' ? 'VBS' : 'VBK';

    return (
        <View wrap={false} style={styles.productListTableItem}>
            <View style={{ ...styles.productListTableCol34 }}>
                <View style={styles.productImageWrapper}>{productImage}</View>
                {
                    <View style={styles.productDetails}>
                        {
                            <Text style={item.expired ? styles.productExpired : styles.productDetailsModel}>
                                {!item?.expired ? item?.modelName : item?.variantKey}
                                {displayCategory && (
                                    <span style={styles.super}> {itemDisplayCategory}</span>
                                )}
                            </Text>
                        }
                        {
                            !item?.expired &&
                            <Text style={styles.productDetailsMaterial}>
                                {item?.variantMaterial && `${item.variantMaterial}`}
                                <div style={styles.textCapitalize}>
                                    {item?.variantColor?.name && ` / ${item.variantColor.name}`}
                                </div>
                                {item?.variantKey && ` / ${item.variantKey}`}
                                {item?.variantWeight && <>{` / ${item.variantWeight} `}g/m&#178;</>}
                            </Text>
                        }
                        {item?.norms && <PdfNorms norms={item.norms!} />}
                    </View>
                }
            </View>

            <View style={styles.productListTableCol9}>
                <Text style={styles.mediumText}>
                    {item?.amountOfWearers !== null && item.amountOfWearers
                        ? item.amountOfWearers
                        : '-'}
                </Text>
            </View>
            <View style={styles.productListTableCol9}>
                <Text style={styles.mediumText}>{item.itemsPerWearer}-fach</Text>
            </View>
            <View style={styles.productListTableCol9}>
                <Text>
                    {item?.basePrice !== null ? `${parseToPrice(item.basePrice!, COMMA)}  €` : '-'}
                </Text>
            </View>
            <View style={styles.productListTableCol9}>
                <Text>
                    {item?.logoFrontPrice !== null
                        ? `${parseToPrice(item.logoFrontPrice!, COMMA)}  €`
                        : '-'}
                </Text>
            </View>
            <View style={styles.productListTableCol9}>
                <Text>
                    {item?.logoBackPrice !== null
                        ? `${parseToPrice(item.logoBackPrice!, COMMA)} €`
                        : '-'}
                </Text>
            </View>
            <View style={styles.productListTableCol9}>
                <Text>
                    {item?.labelPrice !== null ? `${parseToPrice(item.labelPrice!, COMMA)} €` : '-'}
                </Text>
            </View>
            <View
                style={{ ...styles.productListTableCol9, textAlign: 'right', paddingRight: '10px' }}
            >
                <Text style={styles.mediumText}>
                    {parseToPrice(calculatePricePerItem(item)!, COMMA)} €
                </Text>
            </View>
        </View>
    );
};
